<template>
  <div class="pricing-plans">
    <connect-modal :show="showChooseFreeOrPricePlan" bkcolor="#F7F7F7" padding="0">
      <choose-free-or-price-plan @choose-free="onChooseFree" @change-plan="onChangePlan" @close="showChooseFreeOrPricePlan = false" />
    </connect-modal>

    <div class="pricing-plans__heading mt-4">
      <div
        class="pricing-plans__heading__button"
        :class="{
          'pricing-plans__heading__button--current': subscriptionPlanType === 'monthly'
        }"
        @click="subscriptionPlanType = 'monthly'"
      >
        <div class="pricing-plans__heading__button__title">{{ $t('pricing.monthly') | capitalizeFirstLetter }}</div>
        <div class="pricing-plans__heading__button__label">{{ $t('pricing.noSavings') }}</div>
      </div>

      <div
        class="pricing-plans__heading__button"
        :class="{
          'pricing-plans__heading__button--current': subscriptionPlanType === 'annual'
        }"
        @click="subscriptionPlanType = 'annual'"
      >
        <div class="pricing-plans__heading__button__title">{{ $t('pricing.yearly') | capitalizeFirstLetter }}</div>
        <div class="pricing-plans__heading__button__label">{{ '10%' | generateRightTranslations($i18n.locale) }}</div>
      </div>
    </div>

    <div v-if="!isOpenedFeaturesListOnMobile" class="pricing-plans__list">
      <!-- <div class="pricing-plans__list__item">
        <div class="pricing-plans__list__item__heading">
          <h5 class="pricing-plans__list__item__heading__title">{{ $t('features.free') }}</h5>
        </div>

        <div class="pricing-plans__list__item__content">
          <div class="pricing-plans__list__item__content__price">
            <div class="pricing-plans__list__item__content__price__currency"></div>
            <div class="pricing-plans__list__item__content__price__amount">{{ $t('features.free') }}</div>
          </div>

          <div class="pricing-plans__heading pricing-plans__list__item__content__type">
            <div
              class="pricing-plans__heading__button"
              :class="{
                'pricing-plans__heading__button--current': subscriptionPlanType === 'monthly'
              }"
              @click="subscriptionPlanType = 'monthly'"
            >
              <div class="pricing-plans__heading__button__title">{{ $t('pricing.monthly') | capitalizeFirstLetter }}</div>
              <div class="pricing-plans__heading__button__label">{{ $t('pricing.noSavings') }}</div>
            </div>

            <div
              class="pricing-plans__heading__button"
              :class="{
                'pricing-plans__heading__button--current': subscriptionPlanType === 'annual'
              }"
              @click="subscriptionPlanType = 'annual'"
            >
              <div class="pricing-plans__heading__button__title">{{ $t('pricing.yearly') | capitalizeFirstLetter }}</div>
              <div class="pricing-plans__heading__button__label">{{ '10%' | generateRightTranslations($i18n.locale) }}</div>
            </div>
          </div>

          <div class="pricing-plans__list__item__content__users">5 {{ $t('vue.usersIncluded') }}</div>

          <div
            class="pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--desktop"
            @click="() => selectPlan(isAnnualPeriodType ? M_ANNUAL_FREE : M_MONTHLY_FREE)"
          >
            {{ $t('vue.choosePlan') }}
          </div>
          <div
            class="pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--mobile"
            @click="() => selectPlan(isAnnualPeriodType ? M_ANNUAL_FREE : M_MONTHLY_FREE)"
          >
            {{ $t('vue.choosePlan') }}
          </div>

          <div class="pricing-plans__list__item__content__features-list">
            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text">
                {{ $t('features.websiteLiveContactWidgetIncludingChatPhoneAndVideo') }}
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="pricing-plans__list__item">
        <div class="pricing-plans__list__item__heading">
          <h5 class="pricing-plans__list__item__heading__title">{{ $t('pricing.basic') }}</h5>
        </div>

        <div class="pricing-plans__list__item__content">
          <div class="pricing-plans__list__item__content__price">
            <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
            <div class="pricing-plans__list__item__content__price__amount">
              {{ subscriptionPlanType === 'annual' ? basicPriceAnnual : basicPriceMonthly }}
              <span class="pricing-plans__list__item__content__price__amount__period">/mo</span>
            </div>
          </div>

          <!--
            <div class="pricing-plans__list__item__content__worth">{{ $t('vue.worth') }} €{{ subscriptionPlanType === 'annual' ? 540 : 588 }}</div>
          -->

          <div class="pricing-plans__heading pricing-plans__list__item__content__type">
            <div
              class="pricing-plans__heading__button"
              :class="{
                'pricing-plans__heading__button--current': subscriptionPlanType === 'monthly'
              }"
              @click="subscriptionPlanType = 'monthly'"
            >
              <div class="pricing-plans__heading__button__title">{{ $t('pricing.monthly') | capitalizeFirstLetter }}</div>
              <div class="pricing-plans__heading__button__label">{{ $t('pricing.noSavings') }}</div>
            </div>

            <div
              class="pricing-plans__heading__button"
              :class="{
                'pricing-plans__heading__button--current': subscriptionPlanType === 'annual'
              }"
              @click="subscriptionPlanType = 'annual'"
            >
              <div class="pricing-plans__heading__button__title">{{ $t('pricing.yearly') | capitalizeFirstLetter }}</div>
              <div class="pricing-plans__heading__button__label">{{ '10%' | generateRightTranslations($i18n.locale) }}</div>
            </div>
          </div>

          <div class="pricing-plans__list__item__content__users">5 {{ $t('vue.usersIncluded') }}</div>

          <div
            class="pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--desktop"
            @click="() => selectPlan(isAnnualPeriodType ? M_BASIC_ANNUAL : M_BASIC_MONTHLY)"
          >
            {{ $t('vue.choosePlan') }}
          </div>
          <div
            class="pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--mobile"
            @click="() => selectPlan(isAnnualPeriodType ? M_BASIC_ANNUAL : M_BASIC_MONTHLY)"
          >
            {{ $t('vue.choosePlan') }}
          </div>

          <div class="pricing-plans__list__item__content__features-list">
            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text pricing-plans__list__item__content__features-list__item__text--strong">
                {{ $t('features.freeSolutionsBundle') }}
              </div>
            </div>

            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text">
                {{ $t('features.contactFormToCollectLeads') }}
              </div>
            </div>

            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text">
                {{ $t('features.screensharing') }}
              </div>
            </div>
          </div>

          <div class="pricing-plans__list__item__content__features-list">
            <span class="pricing-plans__list__item__content__features-list--header">
              {{ $t('vue.optional') }}
            </span>
            <ul class="pricing-plans__list__item__content__features-list__vega-option">
              <li class="pricing-plans__list__item__content__features-list__item__text">
                <vs-icon size="18px" icon="add" icon-pack="material-icons" class="pricing-plans__list__item__content__features-list__vega-option__icon" />
                <div class="pricing-plans__list__item__content__features-list__vega-option__text">
                  {{ $t('pricing.aiOption') }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="pricing-plans__features-list-toggler pricing-plans__features-list-toggler--mobile">
        <div class="pricing-plans__features-list-toggler__button" @click="toggleFeaturesList('Basic')">
          {{ isOpenedBasicFeaturesList ? $t('features.hideAllBasicFeatures') : $t('features.seeAllBasicFeatures') }}

          <img
            v-if="isOpenedBasicFeaturesList"
            src="@/assets/images/register-funnel/minus-circle.svg"
            alt="Hide list"
            class="pricing-plans__features-list-toggler__button__icon"
          />
          <img v-else src="@/assets/images/register-funnel/plus-circle.svg" alt="Open list" class="pricing-plans__features-list-toggler__button__icon" />
        </div>
      </div>

      <div class="pricing-plans__list__item pricing-plans__list__item--popular">
        <div class="pricing-plans__list__item__heading">
          <h5 class="pricing-plans__list__item__heading__title">{{ $t('pricing.pro') }}</h5>
        </div>

        <div class="pricing-plans__list__item__content">
          <div v-if="proDealImage" class="pricing-plans__list__item__content__deal">
            <img width="200" :src="proDealImage" />
          </div>
          <div class="pricing-plans__list__item__content__label pricing-plans__list__item__content__label--desktop">{{ $t('vue.mostPopular') }}</div>
          <div class="pricing-plans__list__item__content__label pricing-plans__list__item__content__label--mobile">
            {{ $t('vue.mostPopular') }}
          </div>

          <div class="pricing-plans__list__item__content__price">
            <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
            <div class="pricing-plans__list__item__content__price__amount">
              {{ subscriptionPlanType === 'annual' ? proPriceAnnual : proPriceMonthly
              }}<span class="pricing-plans__list__item__content__price__amount__period">/mo </span>
            </div>
          </div>

          <!-- <div class="pricing-plans__list__item__content__worth">{{ $t('vue.worth') }} €{{ subscriptionPlanType === 'annual' ? 2988 : 3348 }}</div> -->

          <div class="pricing-plans__heading pricing-plans__list__item__content__type">
            <div
              class="pricing-plans__heading__button"
              :class="{
                'pricing-plans__heading__button--current': subscriptionPlanType === 'monthly'
              }"
              @click="subscriptionPlanType = 'monthly'"
            >
              <div class="pricing-plans__heading__button__title">{{ $t('pricing.monthly') | capitalizeFirstLetter }}</div>
              <div class="pricing-plans__heading__button__label">{{ $t('pricing.noSavings') }}</div>
            </div>

            <div
              class="pricing-plans__heading__button"
              :class="{
                'pricing-plans__heading__button--current': subscriptionPlanType === 'annual'
              }"
              @click="subscriptionPlanType = 'annual'"
            >
              <div class="pricing-plans__heading__button__title">{{ $t('pricing.yearly') | capitalizeFirstLetter }}</div>
              <div class="pricing-plans__heading__button__label">{{ '10%' | generateRightTranslations($i18n.locale) }}</div>
            </div>
          </div>

          <div class="pricing-plans__list__item__content__users">5 {{ $t('vue.usersIncluded') }}</div>

          <div
            class="pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--desktop"
            @click="() => selectPlan(isAnnualPeriodType ? M_PROFESSIONAL_ANNUAL : M_PROFESSIONAL_MONTHLY)"
          >
            {{ isLotteryWinner && isAnnualPeriodType ? $t('vue.getYourProfitNow') : $t('vue.choosePlan') }}
          </div>
          <div
            class="pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--mobile"
            @click="() => selectPlan(isAnnualPeriodType ? M_PROFESSIONAL_ANNUAL : M_PROFESSIONAL_MONTHLY)"
          >
            {{ isLotteryWinner && isAnnualPeriodType ? $t('vue.getYourProfitNow') : $t('vue.choosePlan') }}
            <img src="@/assets/images/register-funnel/forward-arrow.png" alt="Next" class="pricing-plans__list__item__content__button-main__icon" />
          </div>

          <div class="pricing-plans__list__item__content__features-list">
            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text pricing-plans__list__item__content__features-list__item__text--strong">
                {{ $t('features.basicSolutionsBundle') }}
              </div>
            </div>

            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text">{{ $t('features.appointmentBooking') }}</div>
            </div>

            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text">{{ $t('features.unlimitedVisitorIdentification') }}</div>
            </div>
            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text">{{ $t('features.liveContactViaQRCode') }}</div>
            </div>
            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text">{{ $t('features.ABTesting') }}</div>
            </div>
            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text">{{ $t('features.whitelabel') }}</div>
            </div>
          </div>

          <div class="pricing-plans__list__item__content__features-list">
            <span class="pricing-plans__list__item__content__features-list--header">
              {{ $t('vue.optional') }}
            </span>
            <ul class="pricing-plans__list__item__content__features-list__vega-option">
              <li class="pricing-plans__list__item__content__features-list__item__text">
                <vs-icon size="18px" icon="add" icon-pack="material-icons" class="pricing-plans__list__item__content__features-list__vega-option__icon" />
                <div class="pricing-plans__list__item__content__features-list__vega-option__text">
                  {{ $t('pricing.aiOption') }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="pricing-plans__features-list-toggler pricing-plans__features-list-toggler--popular pricing-plans__features-list-toggler--mobile">
        <div class="pricing-plans__features-list-toggler__button" @click="toggleFeaturesList('Pro')">
          {{ isOpenedProFeaturesList ? $t('features.hideAllProFeatures') : $t('features.seeAllProFeatures') }}

          <img
            v-if="isOpenedProFeaturesList"
            src="@/assets/images/register-funnel/minus-circle.svg"
            alt="Hide list"
            class="pricing-plans__features-list-toggler__button__icon"
          />
          <img v-else src="@/assets/images/register-funnel/plus-circle.svg" alt="Open list" class="pricing-plans__features-list-toggler__button__icon" />
        </div>
      </div>

      <div class="pricing-plans__list__item">
        <div class="pricing-plans__list__item__heading">
          <h5 class="pricing-plans__list__item__heading__title">{{ $t('pricing.enterprise') }}</h5>
        </div>

        <div class="pricing-plans__list__item__content">
          <div class="pricing-plans__list__item__content__price">
            <!-- <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
            <div class="pricing-plans__list__item__content__price__amount">
              {{ subscriptionPlanType === 'annual' ? enterprisePriceAnnual : enterprisePriceMonthly
              }}<span class="pricing-plans__list__item__content__price__amount__period">/mo</span>
            </div> -->
            <div class="pricing-plans__list__item__content__price__amount pricing-plans__list__item__content__price__amount--custom-price">
              {{ $t('pricing.getACustomQuote') }}
            </div>
          </div>

          <!-- <div class="pricing-plans__list__item__content__worth">{{ $t('vue.worth') }} €{{ subscriptionPlanType === 'annual' ? 16140 : 17940 }}</div> -->

          <div class="pricing-plans__heading pricing-plans__list__item__content__type">
            <div
              class="pricing-plans__heading__button"
              :class="{
                'pricing-plans__heading__button--current': subscriptionPlanType === 'monthly'
              }"
              @click="subscriptionPlanType = 'monthly'"
            >
              <div class="pricing-plans__heading__button__title">{{ $t('pricing.monthly') | capitalizeFirstLetter }}</div>
              <div class="pricing-plans__heading__button__label">{{ $t('pricing.noSavings') }}</div>
            </div>

            <div
              class="pricing-plans__heading__button"
              :class="{
                'pricing-plans__heading__button--current': subscriptionPlanType === 'annual'
              }"
              @click="subscriptionPlanType = 'annual'"
            >
              <div class="pricing-plans__heading__button__title">{{ $t('pricing.yearly') | capitalizeFirstLetter }}</div>
              <div class="pricing-plans__heading__button__label">{{ '10%' | generateRightTranslations($i18n.locale) }}</div>
            </div>
          </div>

          <!-- <div class="pricing-plans__list__item__content__users">10 {{ $t('vue.usersIncluded') }}</div> -->
          <div class="pricing-plans__list__item__content__users">{{ $t('pricing.forEstablishedTeams') }}</div>

          <div
            class="pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--desktop"
            @click="() => selectPlan(isAnnualPeriodType ? M_ENTERPRISE_ANNUAL : M_ENTERPRISE_MONTHLY)"
          >
            {{ $t('pricing.contactSales') }}
          </div>
          <div
            class="pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--mobile"
            @click="() => selectPlan(isAnnualPeriodType ? M_ENTERPRISE_ANNUAL : M_ENTERPRISE_MONTHLY)"
          >
            {{ $t('pricing.contactSales') }}
          </div>

          <div class="pricing-plans__list__item__content__features-list">
            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text pricing-plans__list__item__content__features-list__item__text--strong">
                {{ $t('features.proSolutionsBundle') }}
              </div>
            </div>

            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text">{{ $t('features.assignmentRules') }}</div>
            </div>

            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text">{{ $t('features.advancedAnalytics') }}</div>
            </div>

            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text">{{ $t('features.privacySupport') }}</div>
            </div>

            <div class="pricing-plans__list__item__content__features-list__item">
              <ThinCheckIcon class="pricing-plans__list__item__content__features-list__item__icon" />
              <div class="pricing-plans__list__item__content__features-list__item__text">{{ $t('features.personalSetup') }}</div>
            </div>
          </div>

          <div class="pricing-plans__list__item__content__features-list">
            <span class="pricing-plans__list__item__content__features-list--header">
              {{ $t('vue.optional') }}
            </span>
            <ul class="pricing-plans__list__item__content__features-list__vega-option">
              <li class="pricing-plans__list__item__content__features-list__item__text">
                <vs-icon size="18px" icon="add" icon-pack="material-icons" class="pricing-plans__list__item__content__features-list__vega-option__icon" />
                <div class="pricing-plans__list__item__content__features-list__vega-option__text">
                  {{ $t('pricing.aiOption') }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="pricing-plans__features-list-toggler pricing-plans__features-list-toggler--mobile">
        <div class="pricing-plans__features-list-toggler__button" @click="toggleFeaturesList('Enterprise')">
          {{ isOpenedEnterpriseFeaturesList ? $t('features.hideAllEnterpriseFeatures') : $t('features.seeAllEnterpriseFeatures') }}

          <img
            v-if="isOpenedEnterpriseFeaturesList"
            src="@/assets/images/register-funnel/minus-circle.svg"
            alt="Hide list"
            class="pricing-plans__features-list-toggler__button__icon"
          />
          <img v-else src="@/assets/images/register-funnel/plus-circle.svg" alt="Open list" class="pricing-plans__features-list-toggler__button__icon" />
        </div>
      </div>
    </div>

    <div class="pricing-plans__features-list-toggler">
      <div class="pricing-plans__features-list-toggler__button" @click="toggleFeaturesList('All')">
        {{ isOpenedFeaturesList ? $t('features.hideAllFeatures') : $t('features.seeAllFeatures') }}

        <img
          v-if="isOpenedFeaturesList"
          src="@/assets/images/register-funnel/minus-circle.svg"
          alt="Hide list"
          class="pricing-plans__features-list-toggler__button__icon"
        />
        <img v-else src="@/assets/images/register-funnel/plus-circle.svg" alt="Open list" class="pricing-plans__features-list-toggler__button__icon" />
      </div>
    </div>

    <div v-if="isOpenedFeaturesList" class="pricing-plans__features">
      <div class="pricing-plans__features__heading">
        <div class="pricing-plans__features__heading__list">
          <!-- <div class="pricing-plans__features__heading__list__item">
            <h5 class="pricing-plans__features__heading__list__item__title">{{ $t('features.free') }}</h5>
            <div class="pricing-plans__list__item__content__price">
              <div class="pricing-plans__list__item__content__price__currency"></div>
              <div class="pricing-plans__list__item__content__price__amount">{{ $t('features.free') }}</div>
            </div>

            <div class="pricing-plans__list__item__content__button-main" @click="() => selectPlan(isAnnualPeriodType ? M_ANNUAL_FREE : M_MONTHLY_FREE)">
              {{ $t('vue.choosePlan') }}
            </div>
          </div> -->

          <div class="pricing-plans__features__heading__list__item">
            <h5 class="pricing-plans__features__heading__list__item__title">{{ $t('pricing.basic') }}</h5>

            <div class="pricing-plans__list__item__content__price">
              <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
              <div class="pricing-plans__list__item__content__price__amount">
                {{ subscriptionPlanType === 'annual' ? basicPriceAnnual : basicPriceMonthly }}
                <span class="pricing-plans__list__item__content__price__amount__period">/mo</span>
              </div>
            </div>

            <div class="pricing-plans__list__item__content__button-main" @click="() => selectPlan(isAnnualPeriodType ? M_BASIC_ANNUAL : M_BASIC_MONTHLY)">
              {{ $t('vue.choosePlan') }}
            </div>
          </div>

          <div class="pricing-plans__features__heading__list__item">
            <h5 class="pricing-plans__features__heading__list__item__title">{{ $t('pricing.pro') }}</h5>

            <div class="pricing-plans__list__item__content__price">
              <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
              <div class="pricing-plans__list__item__content__price__amount">
                {{ subscriptionPlanType === 'annual' ? proPriceAnnual : proPriceMonthly
                }}<span class="pricing-plans__list__item__content__price__amount__period">/mo</span>
              </div>
            </div>

            <div
              class="pricing-plans__list__item__content__button-main"
              @click="() => selectPlan(isAnnualPeriodType ? M_PROFESSIONAL_ANNUAL : M_PROFESSIONAL_MONTHLY)"
            >
              {{ isLotteryWinner && isAnnualPeriodType ? $t('vue.getYourProfitNow') : $t('vue.choosePlan') }}
            </div>
          </div>

          <div class="pricing-plans__features__heading__list__item">
            <h5 class="pricing-plans__features__heading__list__item__title">{{ $t('pricing.enterprise') }}</h5>

            <div class="pricing-plans__list__item__content__price">
              <!-- <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
              <div class="pricing-plans__list__item__content__price__amount">
                {{ subscriptionPlanType === 'annual' ? enterprisePriceAnnual : enterprisePriceMonthly
                }}<span class="pricing-plans__list__item__content__price__amount__period">/mo</span>
              </div> -->
              <div class="pricing-plans__list__item__content__price__amount__period pricing-plans__list__item__content__price__amount__period--custom-price">
                {{ $t('pricing.customQuote') }}
              </div>
            </div>

            <div
              class="pricing-plans__list__item__content__button-main"
              @click="() => selectPlan(isAnnualPeriodType ? M_ENTERPRISE_ANNUAL : M_ENTERPRISE_MONTHLY)"
            >
              {{ $t('pricing.contactSales') }}
            </div>
          </div>
        </div>
      </div>

      <div class="pricing-plans__features-props">
        <div v-for="(featureSection, featureSectionIndex) in featuresList" :key="featureSection.title">
          <div
            class="pricing-plans__features-props__item-heading"
            :class="{
              'pricing-plans__features-props__item-heading--rounded': featureSectionIndex === 0
            }"
          >
            {{ featureSection.title }}
          </div>

          <div v-for="feature in featureSection.items" :key="feature.title" class="pricing-plans__features-props__item">
            <div class="pricing-plans__features-props__item__value">
              <div class="pricing-plans__features-props__item__value__title">{{ feature.title }}</div>
              <div class="pricing-plans__features-props__item__value__description">{{ feature.description }}</div>
            </div>

            <div class="pricing-plans__features-props__item__marks-list">
              <div class="pricing-plans__features-props__item__marks-list__item">
                <template v-if="feature.isCustomField">
                  {{ feature.customTextFree }}
                </template>

                <template v-else>
                  <ThinCheckIcon v-if="feature.isFree" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                  <img v-else src="@/assets/images/register-funnel/cross.svg" alt="Check" class="pricing-plans__features-props__item__marks-list__item__icon" />
                </template>
              </div>
              <div class="pricing-plans__features-props__item__marks-list__item">
                <template v-if="feature.isCustomField">
                  {{ feature.customTextBasic }}
                </template>

                <template v-else>
                  <ThinCheckIcon v-if="feature.isBasic" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                  <img v-else src="@/assets/images/register-funnel/cross.svg" alt="Check" class="pricing-plans__features-props__item__marks-list__item__icon" />
                </template>
              </div>
              <div class="pricing-plans__features-props__item__marks-list__item">
                <template v-if="feature.isCustomField">
                  {{ feature.customTextPro }}
                </template>

                <template v-else>
                  <ThinCheckIcon v-if="feature.isPro" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                  <img v-else src="@/assets/images/register-funnel/cross.svg" alt="Check" class="pricing-plans__features-props__item__marks-list__item__icon" />
                </template>
              </div>
              <div class="pricing-plans__features-props__item__marks-list__item">
                <template v-if="feature.isCustomField">
                  {{ feature.customTextEnterprise }}
                </template>

                <template v-else>
                  <ThinCheckIcon v-if="feature.isEnterprise" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                  <img v-else src="@/assets/images/register-funnel/cross.svg" alt="Check" class="pricing-plans__features-props__item__marks-list__item__icon" />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isOpenedFeaturesListOnMobile" class="pricing-plans__features-mobile-list">
      <div class="pricing-plans__features-mobile-list__item">
        <div class="pricing-plans__features-mobile-list__item__heading">
          <div class="pricing-plans__features-mobile-list__item__heading__main">
            <div class="pricing-plans__features-mobile-list__item__heading__title">Basic</div>

            <!-- <div v-if="subscriptionPlanType === 'annual' && isOpenedBasicFeaturesList" class="pricing-plans__features-mobile-list__item__heading__saving">
                        you save €{{ countPriceSavings(basicPriceMonthly, basicPriceAnnual) }}
                      </div> -->

            <div class="pricing-plans__list__item__content__price">
              <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
              <div class="pricing-plans__list__item__content__price__amount">
                {{ subscriptionPlanType === 'annual' ? basicPriceAnnual : basicPriceMonthly
                }}<span class="pricing-plans__list__item__content__price__amount__period">/mo</span>
              </div>
            </div>
          </div>

          <div
            v-if="isOpenedBasicFeaturesList"
            class="pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--mobile"
            @click="() => selectPlan(isAnnualPeriodType ? M_BASIC_ANNUAL : M_BASIC_MONTHLY)"
          >
            {{ $t('vue.choosePlan') }}
          </div>
        </div>

        <div v-if="isOpenedBasicFeaturesList" class="pricing-plans__features-props">
          <div v-for="featureSection in featuresList" :key="featureSection.title">
            <div class="pricing-plans__features-props__item-heading">{{ featureSection.title }}</div>

            <div v-for="feature in featureSection.items" :key="feature.title" class="pricing-plans__features-props__item">
              <div class="pricing-plans__features-props__item__value">
                <div class="pricing-plans__features-props__item__value__title">{{ feature.title }}</div>
                <div class="pricing-plans__features-props__item__value__description">{{ feature.description }}</div>
              </div>

              <div class="pricing-plans__features-props__item__marks-list">
                <div class="pricing-plans__features-props__item__marks-list__item">
                  <template v-if="feature.isCustomField">
                    {{ feature.customTextBasic }}
                  </template>

                  <template v-else>
                    <ThinCheckIcon v-if="feature.isBasic" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                    <img
                      v-else
                      src="@/assets/images/register-funnel/cross.svg"
                      alt="Check"
                      class="pricing-plans__features-props__item__marks-list__item__icon"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pricing-plans__features-mobile-list__item pricing-plans__features-mobile-list__item--popular">
        <div class="pricing-plans__features-mobile-list__item__heading">
          <div class="pricing-plans__features-mobile-list__item__heading__main">
            <div class="pricing-plans__features-mobile-list__item__heading__title">Pro</div>

            <!-- <div v-if="subscriptionPlanType === 'annual' && isOpenedProFeaturesList" class="pricing-plans__features-mobile-list__item__heading__saving">
                        you save €{{ countPriceSavings(proPriceMonthly, proPriceAnnual) }}
                      </div> -->

            <div class="pricing-plans__list__item__content__price">
              <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
              <div class="pricing-plans__list__item__content__price__amount">
                {{ subscriptionPlanType === 'annual' ? proPriceAnnual : proPriceMonthly
                }}<span class="pricing-plans__list__item__content__price__amount__period">/mo</span>
              </div>
            </div>
          </div>

          <div
            v-if="isOpenedProFeaturesList"
            class="pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--mobile"
            @click="() => selectPlan(isAnnualPeriodType ? M_PROFESSIONAL_ANNUAL : M_PROFESSIONAL_MONTHLY)"
          >
            {{ isLotteryWinner && isAnnualPeriodType ? $t('vue.getYourProfitNow') : $t('vue.choosePlan') }}

            <img src="@/assets/images/register-funnel/forward-arrow.png" alt="Next" class="pricing-plans__list__item__content__button-main__icon" />
          </div>
        </div>

        <div v-if="isOpenedProFeaturesList" class="pricing-plans__features-props">
          <div v-for="featureSection in featuresList" :key="featureSection.title">
            <div class="pricing-plans__features-props__item-heading">{{ featureSection.title }}</div>

            <div v-for="feature in featureSection.items" :key="feature.title" class="pricing-plans__features-props__item">
              <div class="pricing-plans__features-props__item__value">
                <div class="pricing-plans__features-props__item__value__title">{{ feature.title }}</div>
                <div class="pricing-plans__features-props__item__value__description">{{ feature.description }}</div>
              </div>

              <div class="pricing-plans__features-props__item__marks-list">
                <div class="pricing-plans__features-props__item__marks-list__item">
                  <template v-if="feature.isCustomField">
                    {{ feature.customTextPro }}
                  </template>

                  <template v-else>
                    <ThinCheckIcon v-if="feature.isPro" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                    <img
                      v-else
                      src="@/assets/images/register-funnel/cross.svg"
                      alt="Check"
                      class="pricing-plans__features-props__item__marks-list__item__icon"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pricing-plans__features-mobile-list__item">
        <div class="pricing-plans__features-mobile-list__item__heading">
          <div class="pricing-plans__features-mobile-list__item__heading__main">
            <div class="pricing-plans__features-mobile-list__item__heading__title">Enterprise</div>

            <!-- <div v-if="subscriptionPlanType === 'annual' && isOpenedEnterpriseFeaturesList" class="pricing-plans__features-mobile-list__item__heading__saving">
                        you save €{{ countPriceSavings(enterprisePriceMonthly, enterprisePriceAnnual) }}
                      </div> -->

            <div class="pricing-plans__list__item__content__price">
              <!-- <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
              <div class="pricing-plans__list__item__content__price__amount">
                {{ subscriptionPlanType === 'annual' ? enterprisePriceAnnual : enterprisePriceMonthly
                }}<span class="pricing-plans__list__item__content__price__amount__period">/mo</span>
              </div> -->
              <div class="pricing-plans__list__item__content__price__amount pricing-plans__list__item__content__price__amount--custom-price">
                {{ $t('pricing.customQuote') }}
              </div>
            </div>
          </div>

          <div
            v-if="isOpenedEnterpriseFeaturesList"
            class="pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--mobile"
            @click="() => selectPlan(isAnnualPeriodType ? M_ENTERPRISE_ANNUAL : M_ENTERPRISE_MONTHLY)"
          >
            {{ $t('pricing.contactSales') }}
          </div>
        </div>

        <div v-if="isOpenedEnterpriseFeaturesList" class="pricing-plans__features-props">
          <div v-for="featureSection in featuresList" :key="featureSection.title">
            <div class="pricing-plans__features-props__item-heading">{{ featureSection.title }}</div>

            <div v-for="feature in featureSection.items" :key="feature.title" class="pricing-plans__features-props__item">
              <div class="pricing-plans__features-props__item__value">
                <div class="pricing-plans__features-props__item__value__title">{{ feature.title }}</div>
                <div class="pricing-plans__features-props__item__value__description">{{ feature.description }}</div>
              </div>

              <div class="pricing-plans__features-props__item__marks-list">
                <div class="pricing-plans__features-props__item__marks-list__item">
                  <template v-if="feature.isCustomField">
                    {{ feature.customTextEnterprise }}
                  </template>

                  <template v-else>
                    <ThinCheckIcon v-if="feature.isEnterprise" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                    <img
                      v-else
                      src="@/assets/images/register-funnel/cross.svg"
                      alt="Check"
                      class="pricing-plans__features-props__item__marks-list__item__icon"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pricing-plans__features-mobile-list__button" @click="returnToAllPlans">
        <ArrowLongIcon class="mr-2" color="rgb(135, 136, 148)" :direction="'left'" />

        Return to All Plans
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { currency } from '@/mixins/currency'
import { msubscription } from '@/mixins/msubscription'
import { commonFunctions } from '@/mixins/commonFunctions'

import ThinCheckIcon from '@/components/icons/ThinCheckIcon'
import ArrowLongIcon from '@/components/icons/ArrowLongIcon'
import ConnectModal from '@/components/ConnectModal.vue'
import ChooseFreeOrPricePlan from '@/components/ChooseFreeOrPricePlan.vue'

export default {
  name: 'MolliePricingPlans',
  mixins: [commonFunctions, currency, msubscription],
  components: {
    ThinCheckIcon,
    ConnectModal,
    ChooseFreeOrPricePlan,
    ArrowLongIcon
  },
  props: {
    isOpenedFeaturesList: {
      type: Boolean
    },
    isOpenedBasicFeaturesList: {
      type: Boolean
    },
    isOpenedProFeaturesList: {
      type: Boolean
    },
    isOpenedEnterpriseFeaturesList: {
      type: Boolean
    },
    isOpenedFeaturesListOnMobile: {
      type: Boolean
    },
    featuresList: {
      type: Array
    },
    isLotteryWinner: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    M_MONTHLY_FREE: 'M_MONTHLY_FREE',
    M_ANNUAL_FREE: 'M_ANNUAL_FREE',
    M_BASIC_MONTHLY: 'M_BASIC_MONTHLY',
    M_BASIC_ANNUAL: 'M_BASIC_ANNUAL',
    M_PROFESSIONAL_MONTHLY: 'M_PROFESSIONAL_MONTHLY',
    M_PROFESSIONAL_ANNUAL: 'M_PROFESSIONAL_ANNUAL',
    M_ENTERPRISE_MONTHLY: 'M_ENTERPRISE_MONTHLY',
    M_ENTERPRISE_ANNUAL: 'M_ENTERPRISE_ANNUAL',
    subscriptionPlanType: 'annual',
    selectedPlanName: null,
    showChooseFreeOrPricePlan: false
  }),
  computed: {
    ...mapGetters({
      companyData: 'company'
    }),
    isAnnualPeriodType() {
      return this.subscriptionPlanType === 'annual'
    },
    proDealImage() {
      if (this.isAnnualPeriodType) {
        return this.proPriceAnnualDealImage
      } else {
        return this.proPriceMonthlyDealImage
      }
    },
    HAS_MOLLIE_SUBSCRIPTION() {
      return (
        this.companyData &&
        Boolean(this.companyData.subscriptionPlanId) &&
        Boolean(this.companyData.mollieSubscriptionId) &&
        Boolean(this.companyData.mollieCustomerId) &&
        this.companyData.paymentStatus !== 'subscription-cancelled'
      )
    }
  },
  filters: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    generateRightTranslations(string, locale) {
      return locale === 'de' ? `Sparen ${string}` : `${string} Save`
    }
  },
  methods: {
    onChooseFree() {
      this.showChooseFreeOrPricePlan = false
      this.$emit('select-plan', this.selectedPlanName)
    },
    onChangePlan() {
      this.showChooseFreeOrPricePlan = false
    },
    toggleFeaturesList(type) {
      this.$serverBus.$emit('toggle-features-list', { type, value: !this.isOpenedFeaturesList })
    },
    returnToAllPlans() {
      this.$serverBus.$emit('return-to-all-plans')
    },
    selectPlan(name) {
      if ([this.M_ENTERPRISE_MONTHLY, this.M_ENTERPRISE_ANNUAL].includes(name)) {
        window.open('https://app.letsconnect.at/pages/qr-connect?cid=9Q6YqYKY5NCONiVpUgPY', '_blank').focus()
        return
      }

      if ([this.M_MONTHLY_FREE, this.M_ANNUAL_FREE].includes(name)) {
        this.selectedPlanName = name
        this.showChooseFreeOrPricePlan = true
      } else {
        this.$emit('select-plan', name)
      }
    },
    SHOW_PLAN_AS_FREE(planId) {
      const plans = this.allPlans
      const currentPlan = plans.find((el) => el.id === planId)
      return currentPlan.isFree
    }
  }
}
</script>

<style lang="scss" scoped>
.pricing-plans {
  margin: 0 auto;
  width: 1024px;
  max-width: 100%;

  &__heading {
    display: flex;
    justify-content: center;

    @media (max-width: 991px) {
      display: none;
    }

    &__button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 142px;
      height: 54px;
      border: 1px solid rgb(217, 219, 221);
      border-radius: 8px 0px 0px 8px;

      &:hover {
        background: rgb(252, 254, 255);
        cursor: pointer;
      }

      &:last-of-type {
        border-radius: 0px 8px 8px 0px;
      }

      &__title {
        color: rgb(83, 83, 91);
        font-family: 'Larsseit-Medium';
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.0833333px;
      }

      &__label {
        color: rgb(217, 219, 221);
        font-family: 'Larsseit-Extra-Bold';
        font-size: 10px;
        line-height: 12px;
        font-weight: 900;
        text-align: center;
        letter-spacing: 0.944444px;
        text-transform: uppercase;
      }

      &--current {
        border-color: rgb(130, 206, 255);

        .pricing-plans {
          &__heading {
            &__button {
              &__title {
                color: rgb(38, 38, 41);
                font-family: 'Larsseit-Bold';
              }

              &__label {
                color: rgb(66, 181, 255);
              }
            }
          }
        }
      }
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    width: 100%;

    margin-top: 25px;

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      display: flex;
      flex-direction: column;
      margin-top: 43px;
      width: calc(100% / 4 - 24px / 4);

      @media (max-width: 991px) {
        margin-top: 26px;
        width: 100%;
        max-width: 480px;
      }

      &__heading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        border-radius: 12px 12px 0 0;

        @media (max-width: 991px) {
          background: rgb(232, 246, 255);
        }

        &__title {
          color: rgb(38, 38, 41);
          font-family: 'Larsseit-Bold';
          font-size: 20px;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.111111px;
          text-transform: capitalize;
        }
      }

      &__content {
        flex: 1;
        position: relative;
        padding: 40px 30px 34px;
        width: 100%;
        background: rgb(244, 250, 255);
        border-radius: 12px;

        &__deal {
          position: absolute;
          left: -103px;
          top: -105px;
          z-index: 1;
        }

        @media (max-width: 991px) {
          border-radius: 0 0 12px 12px;
        }

        &__label {
          position: absolute;
          top: 11px;
          left: 0;
          width: 100%;
          color: rgb(66, 181, 255);
          font-weight: 800;
          font-family: 'Larsseit-Bold';
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          letter-spacing: 1.13333px;
          text-transform: uppercase;

          &--desktop {
            @media (max-width: 991px) {
              display: none;
            }
          }

          &--mobile {
            display: none;

            @media (max-width: 991px) {
              display: block;
            }
          }
        }

        &__price {
          display: flex;
          justify-content: center;

          &__currency {
            padding-top: 3px;
            padding-right: 3px;
            color: rgb(38, 38, 41);
            font-family: 'Larsseit-Medium';
            font-size: 22px;
            line-height: 1;
            letter-spacing: -0.122222px;
          }

          &__amount {
            display: flex;
            align-items: flex-end;
            color: rgb(38, 38, 41);
            font-family: 'Larsseit-Bold';
            font-size: 40px;
            line-height: 0.9;
            letter-spacing: -0.222222px;

            &__period {
              color: rgb(83, 83, 91);
              font-family: 'Larsseit-Medium';
              font-size: 14px;
              line-height: 1;
              text-align: right;
              letter-spacing: -0.0777778px;
            }

            &--custom-price {
              color: #262629;
              text-transform: none;
              font-family: 'Larsseit-Bold';
              font-weight: 700;
              text-align: center;
              font-size: 20px;
              min-height: 36px;
            }
          }
        }

        &__worth {
          margin-top: 6px;
          color: rgb(66, 181, 255);
          font-family: 'Larsseit-Medium';
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          letter-spacing: -0.0888889px;
        }

        &__type {
          display: none;
          margin-top: 24px;

          @media (max-width: 991px) {
            display: flex;
          }

          .pricing-plans__heading__button {
            &__label {
              color: rgb(163, 205, 232);
            }

            &--current {
              background: rgb(255, 255, 255);

              .pricing-plans__heading__button {
                &__label {
                  color: rgb(66, 181, 255);
                }
              }
            }
          }
        }

        &__users {
          margin-top: 20px;
          font-family: 'Larsseit-Medium';
          color: rgb(38, 38, 41);
          font-size: 15px;
          line-height: 15px;
          letter-spacing: -0.0833333px;
          text-align: center;
        }

        &__button-main {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 14px;
          height: 50px;
          color: rgb(255, 255, 255);
          font-family: 'Larsseit-Bold';
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.1px;
          background: rgb(130, 206, 255);
          border-radius: 8px;
          text-align: center;
          text-transform: capitalize;

          &:hover {
            background: rgb(66, 181, 255);
            transition: all 0.2s linear;
            cursor: pointer;
          }

          &-wrapper {
            display: flex;
            justify-content: center;
          }

          &__icon {
            margin-left: 11px;
            width: 18px;
          }

          &--desktop {
            @media (max-width: 991px) {
              display: none;
            }
          }

          &--mobile {
            display: none;
            margin-top: 17px;

            @media (max-width: 991px) {
              display: flex;
            }
          }
        }

        &__features-list {
          margin-top: 23px;
          &--header {
            color: #262629;
            margin-bottom: 5px;
            font-size: 15px;
            font-weight: 700;
          }

          @media (max-width: 991px) {
            max-width: 200px;
            margin-left: auto;
            margin-right: auto;
          }

          &__vega-option {
            margin-top: 10px;
            position: relative;
            &__icon {
              position: absolute;
              font-weight: 600;
              color: #42b5ff;
            }

            &__text {
              margin-left: 20px;
              color: rgb(38, 38, 41);
              font-size: 15px;
              line-height: 18px;
              letter-spacing: -0.0833333px;

              &--strong {
                font-family: 'Larsseit-Bold';
              }
            }
          }

          &__item {
            display: flex;
            align-items: flex-start;
            margin-top: 10px;
            position: relative;

            &:first-of-type {
              margin-top: 0;
            }

            &__icon {
              margin-top: 5px;
              margin-right: 5px;
              min-width: 11px;
            }

            &__text {
              color: rgb(38, 38, 41);
              font-size: 15px;
              line-height: 18px;
              letter-spacing: -0.0833333px;

              &--strong {
                font-family: 'Larsseit-Bold';
              }
            }
          }
        }
      }

      &--popular {
        @media (max-width: 991px) {
          order: -1;
          margin-top: 0;
        }

        .pricing-plans {
          &__list {
            &__item {
              &__heading {
                background: rgb(48, 52, 247);

                &__title {
                  color: rgb(255, 255, 255);
                }
              }

              &__content {
                border-radius: 0 0 12px 12px;

                &__button-main {
                  background: rgb(48, 52, 247);

                  &:hover {
                    background: rgb(42, 45, 222);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__features-list {
    &-toggler {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      @media (max-width: 991px) {
        display: none;
      }

      &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(38, 38, 41);
        font-family: 'Larsseit-Bold';
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.1px;

        &:hover {
          cursor: pointer;
        }

        &__icon {
          margin-left: 5px;
          width: 16px;
          height: 16px;
        }
      }

      &--popular {
        order: -1;
      }

      &--mobile {
        display: none;

        @media (max-width: 991px) {
          display: flex;
        }
      }
    }
  }

  &__features {
    margin-top: 40px;

    @media (max-width: 991px) {
      display: none;
    }

    &__heading {
      padding-left: 250px;

      &__list {
        display: flex;
        border: 1px solid #c8e2f3;
        border-bottom: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px 16px 18px;
          width: 25%;

          &__title {
            color: rgb(38, 38, 41);
            font-family: 'Larsseit-Bold';
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.111111px;
          }

          .pricing-plans__list__item__content {
            &__price {
              margin-top: 6px;

              &__currency {
                font-size: 19px;
              }

              &__amount {
                font-size: 35px;

                &__period {
                  font-size: 12px;
                }

                &--custom-price {
                  color: #262629;
                  text-transform: none;
                  font-family: 'Larsseit-Bold';
                  font-weight: 700;
                  text-align: center;
                  font-size: 20px;
                }
              }
            }

            &__button-main {
              display: inline-block;
              margin-top: 14px;
              padding: 8px 12px;
              height: auto;
              color: rgb(255, 255, 255);
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }
    }

    &-props {
      &__item {
        display: flex;
        border: 1px solid #c8e2f3;
        border-top: 0;

        &-heading {
          padding: 10px 15px 10px 35px;
          color: rgb(38, 38, 41);
          font-family: 'Larsseit-Bold';
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.67px;
          background: rgb(232, 246, 255);
          border: 1px solid rgb(200, 226, 243);

          &--rounded {
            border-radius: 8px 0px 0px;
          }
        }

        &__value {
          padding: 10px 15px 10px 35px;
          width: 250px;
          border-right: 1px solid #c8e2f3;

          &__title {
            color: rgb(38, 38, 41);
            font-size: 16px;
            line-height: 1.25;
            font-family: 'Larsseit-Medium';
          }

          &__description {
            color: rgb(83, 83, 91);
            font-family: 'Larsseit-Light';
            font-size: 14px;
            line-height: 1.643;
          }
        }

        &__marks-list {
          display: flex;
          flex: 1;

          &__item {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            color: rgb(48, 52, 247);
            font-family: 'Larsseit-Medium';
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }

  &__features-mobile-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 480px;

    &__item {
      margin-top: 6px;
      width: 100%;
      border: 1px solid rgb(200, 226, 243);
      border-radius: 8px;

      &--popular {
        order: -1;
        .pricing-plans__list__item__content__button-main {
          background: rgb(48, 52, 247);
        }
      }

      &__heading {
        padding: 12px 16px;

        &__main {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .pricing-plans__list__item__content__price {
          &__currency {
            padding-top: 0;
            font-size: 16px;
          }

          &__amount {
            font-size: 28px;

            &__period {
              font-size: 12px;
            }

            &--custom-price {
              color: #262629;
              text-transform: none;
              font-family: 'Larsseit-Bold';
              font-weight: 700;
              text-align: center;
              font-size: 20px;
            }
          }
        }

        &__title {
          color: rgb(38, 38, 41);
          font-family: 'Larsseit-Bold';
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.133333px;
        }

        &__saving {
          margin-left: auto;
          margin-right: 13px;
          color: rgb(66, 181, 255);
          font-family: 'Larsseit-Medium';
          font-size: 15px;
          line-height: 16px;
          letter-spacing: -0.0833333px;
        }
      }

      .pricing-plans__features-props {
        &__item {
          &-heading {
            padding-left: 16px;
          }

          &__value {
            flex: 1;
            padding-left: 16px;
            width: auto;
            border-right: none;
          }

          &__marks-list {
            flex: none;
            padding-right: 5px;
            min-width: 35px;
          }
        }
      }
    }

    &__button {
      display: flex;
      align-items: center;
      margin-top: 20px;
      color: rgb(135, 136, 148);
      font-family: 'Larsseit-Bold';
      font-size: 18px;
      line-height: 18px;
      letter-spacing: -0.1px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
