var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pricing-plans" },
    [
      _c(
        "connect-modal",
        {
          attrs: {
            show: _vm.showChooseFreeOrPricePlan,
            bkcolor: "#F7F7F7",
            padding: "0",
          },
        },
        [
          _c("choose-free-or-price-plan", {
            on: {
              "choose-free": _vm.onChooseFree,
              "change-plan": _vm.onChangePlan,
              close: function ($event) {
                _vm.showChooseFreeOrPricePlan = false
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "pricing-plans__heading mt-4" }, [
        _c(
          "div",
          {
            staticClass: "pricing-plans__heading__button",
            class: {
              "pricing-plans__heading__button--current":
                _vm.subscriptionPlanType === "monthly",
            },
            on: {
              click: function ($event) {
                _vm.subscriptionPlanType = "monthly"
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "pricing-plans__heading__button__title" },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("capitalizeFirstLetter")(_vm.$t("pricing.monthly"))
                  )
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "pricing-plans__heading__button__label" },
              [_vm._v(_vm._s(_vm.$t("pricing.noSavings")))]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "pricing-plans__heading__button",
            class: {
              "pricing-plans__heading__button--current":
                _vm.subscriptionPlanType === "annual",
            },
            on: {
              click: function ($event) {
                _vm.subscriptionPlanType = "annual"
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "pricing-plans__heading__button__title" },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("capitalizeFirstLetter")(_vm.$t("pricing.yearly"))
                  )
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "pricing-plans__heading__button__label" },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("generateRightTranslations")("10%", _vm.$i18n.locale)
                  )
                ),
              ]
            ),
          ]
        ),
      ]),
      !_vm.isOpenedFeaturesListOnMobile
        ? _c("div", { staticClass: "pricing-plans__list" }, [
            _c("div", { staticClass: "pricing-plans__list__item" }, [
              _c("div", { staticClass: "pricing-plans__list__item__heading" }, [
                _c(
                  "h5",
                  { staticClass: "pricing-plans__list__item__heading__title" },
                  [_vm._v(_vm._s(_vm.$t("pricing.basic")))]
                ),
              ]),
              _c("div", { staticClass: "pricing-plans__list__item__content" }, [
                _c(
                  "div",
                  { staticClass: "pricing-plans__list__item__content__price" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__price__currency",
                      },
                      [_vm._v(_vm._s(_vm.currentCurrencySymbol))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__price__amount",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.subscriptionPlanType === "annual"
                                ? _vm.basicPriceAnnual
                                : _vm.basicPriceMonthly
                            ) +
                            "\n            "
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__price__amount__period",
                          },
                          [_vm._v("/mo")]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "pricing-plans__heading pricing-plans__list__item__content__type",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "pricing-plans__heading__button",
                        class: {
                          "pricing-plans__heading__button--current":
                            _vm.subscriptionPlanType === "monthly",
                        },
                        on: {
                          click: function ($event) {
                            _vm.subscriptionPlanType = "monthly"
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__heading__button__title",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("capitalizeFirstLetter")(
                                  _vm.$t("pricing.monthly")
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__heading__button__label",
                          },
                          [_vm._v(_vm._s(_vm.$t("pricing.noSavings")))]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "pricing-plans__heading__button",
                        class: {
                          "pricing-plans__heading__button--current":
                            _vm.subscriptionPlanType === "annual",
                        },
                        on: {
                          click: function ($event) {
                            _vm.subscriptionPlanType = "annual"
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__heading__button__title",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("capitalizeFirstLetter")(
                                  _vm.$t("pricing.yearly")
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__heading__button__label",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("generateRightTranslations")(
                                  "10%",
                                  _vm.$i18n.locale
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "pricing-plans__list__item__content__users" },
                  [_vm._v("5 " + _vm._s(_vm.$t("vue.usersIncluded")))]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--desktop",
                    on: {
                      click: function () {
                        return _vm.selectPlan(
                          _vm.isAnnualPeriodType
                            ? _vm.M_BASIC_ANNUAL
                            : _vm.M_BASIC_MONTHLY
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("vue.choosePlan")) +
                        "\n        "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--mobile",
                    on: {
                      click: function () {
                        return _vm.selectPlan(
                          _vm.isAnnualPeriodType
                            ? _vm.M_BASIC_ANNUAL
                            : _vm.M_BASIC_MONTHLY
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("vue.choosePlan")) +
                        "\n        "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "pricing-plans__list__item__content__features-list",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list__item",
                      },
                      [
                        _c("ThinCheckIcon", {
                          staticClass:
                            "pricing-plans__list__item__content__features-list__item__icon",
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item__text pricing-plans__list__item__content__features-list__item__text--strong",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("features.freeSolutionsBundle")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list__item",
                      },
                      [
                        _c("ThinCheckIcon", {
                          staticClass:
                            "pricing-plans__list__item__content__features-list__item__icon",
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item__text",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("features.contactFormToCollectLeads")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list__item",
                      },
                      [
                        _c("ThinCheckIcon", {
                          staticClass:
                            "pricing-plans__list__item__content__features-list__item__icon",
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item__text",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("features.screensharing")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "pricing-plans__list__item__content__features-list",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list--header",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("vue.optional")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list__vega-option",
                      },
                      [
                        _c(
                          "li",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item__text",
                          },
                          [
                            _c("vs-icon", {
                              staticClass:
                                "pricing-plans__list__item__content__features-list__vega-option__icon",
                              attrs: {
                                size: "18px",
                                icon: "add",
                                "icon-pack": "material-icons",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__features-list__vega-option__text",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("pricing.aiOption")) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "pricing-plans__features-list-toggler pricing-plans__features-list-toggler--mobile",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "pricing-plans__features-list-toggler__button",
                    on: {
                      click: function ($event) {
                        return _vm.toggleFeaturesList("Basic")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.isOpenedBasicFeaturesList
                            ? _vm.$t("features.hideAllBasicFeatures")
                            : _vm.$t("features.seeAllBasicFeatures")
                        ) +
                        "\n\n        "
                    ),
                    _vm.isOpenedBasicFeaturesList
                      ? _c("img", {
                          staticClass:
                            "pricing-plans__features-list-toggler__button__icon",
                          attrs: {
                            src: require("@/assets/images/register-funnel/minus-circle.svg"),
                            alt: "Hide list",
                          },
                        })
                      : _c("img", {
                          staticClass:
                            "pricing-plans__features-list-toggler__button__icon",
                          attrs: {
                            src: require("@/assets/images/register-funnel/plus-circle.svg"),
                            alt: "Open list",
                          },
                        }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "pricing-plans__list__item pricing-plans__list__item--popular",
              },
              [
                _c(
                  "div",
                  { staticClass: "pricing-plans__list__item__heading" },
                  [
                    _c(
                      "h5",
                      {
                        staticClass:
                          "pricing-plans__list__item__heading__title",
                      },
                      [_vm._v(_vm._s(_vm.$t("pricing.pro")))]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "pricing-plans__list__item__content" },
                  [
                    _vm.proDealImage
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__deal",
                          },
                          [
                            _c("img", {
                              attrs: { width: "200", src: _vm.proDealImage },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__label pricing-plans__list__item__content__label--desktop",
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.mostPopular")))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__label pricing-plans__list__item__content__label--mobile",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("vue.mostPopular")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__price",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__price__currency",
                          },
                          [_vm._v(_vm._s(_vm.currentCurrencySymbol))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__price__amount",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.subscriptionPlanType === "annual"
                                    ? _vm.proPriceAnnual
                                    : _vm.proPriceMonthly
                                )
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__price__amount__period",
                              },
                              [_vm._v("/mo ")]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__heading pricing-plans__list__item__content__type",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "pricing-plans__heading__button",
                            class: {
                              "pricing-plans__heading__button--current":
                                _vm.subscriptionPlanType === "monthly",
                            },
                            on: {
                              click: function ($event) {
                                _vm.subscriptionPlanType = "monthly"
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__heading__button__title",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("capitalizeFirstLetter")(
                                      _vm.$t("pricing.monthly")
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__heading__button__label",
                              },
                              [_vm._v(_vm._s(_vm.$t("pricing.noSavings")))]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "pricing-plans__heading__button",
                            class: {
                              "pricing-plans__heading__button--current":
                                _vm.subscriptionPlanType === "annual",
                            },
                            on: {
                              click: function ($event) {
                                _vm.subscriptionPlanType = "annual"
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__heading__button__title",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("capitalizeFirstLetter")(
                                      _vm.$t("pricing.yearly")
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__heading__button__label",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("generateRightTranslations")(
                                      "10%",
                                      _vm.$i18n.locale
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__users",
                      },
                      [_vm._v("5 " + _vm._s(_vm.$t("vue.usersIncluded")))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--desktop",
                        on: {
                          click: function () {
                            return _vm.selectPlan(
                              _vm.isAnnualPeriodType
                                ? _vm.M_PROFESSIONAL_ANNUAL
                                : _vm.M_PROFESSIONAL_MONTHLY
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.isLotteryWinner && _vm.isAnnualPeriodType
                                ? _vm.$t("vue.getYourProfitNow")
                                : _vm.$t("vue.choosePlan")
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--mobile",
                        on: {
                          click: function () {
                            return _vm.selectPlan(
                              _vm.isAnnualPeriodType
                                ? _vm.M_PROFESSIONAL_ANNUAL
                                : _vm.M_PROFESSIONAL_MONTHLY
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.isLotteryWinner && _vm.isAnnualPeriodType
                                ? _vm.$t("vue.getYourProfitNow")
                                : _vm.$t("vue.choosePlan")
                            ) +
                            "\n          "
                        ),
                        _c("img", {
                          staticClass:
                            "pricing-plans__list__item__content__button-main__icon",
                          attrs: {
                            src: require("@/assets/images/register-funnel/forward-arrow.png"),
                            alt: "Next",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item",
                          },
                          [
                            _c("ThinCheckIcon", {
                              staticClass:
                                "pricing-plans__list__item__content__features-list__item__icon",
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__features-list__item__text pricing-plans__list__item__content__features-list__item__text--strong",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("features.basicSolutionsBundle")
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item",
                          },
                          [
                            _c("ThinCheckIcon", {
                              staticClass:
                                "pricing-plans__list__item__content__features-list__item__icon",
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__features-list__item__text",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("features.appointmentBooking"))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item",
                          },
                          [
                            _c("ThinCheckIcon", {
                              staticClass:
                                "pricing-plans__list__item__content__features-list__item__icon",
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__features-list__item__text",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "features.unlimitedVisitorIdentification"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item",
                          },
                          [
                            _c("ThinCheckIcon", {
                              staticClass:
                                "pricing-plans__list__item__content__features-list__item__icon",
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__features-list__item__text",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("features.liveContactViaQRCode")
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item",
                          },
                          [
                            _c("ThinCheckIcon", {
                              staticClass:
                                "pricing-plans__list__item__content__features-list__item__icon",
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__features-list__item__text",
                              },
                              [_vm._v(_vm._s(_vm.$t("features.ABTesting")))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item",
                          },
                          [
                            _c("ThinCheckIcon", {
                              staticClass:
                                "pricing-plans__list__item__content__features-list__item__icon",
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__features-list__item__text",
                              },
                              [_vm._v(_vm._s(_vm.$t("features.whitelabel")))]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list--header",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("vue.optional")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _c(
                          "ul",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__vega-option",
                          },
                          [
                            _c(
                              "li",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__features-list__item__text",
                              },
                              [
                                _c("vs-icon", {
                                  staticClass:
                                    "pricing-plans__list__item__content__features-list__vega-option__icon",
                                  attrs: {
                                    size: "18px",
                                    icon: "add",
                                    "icon-pack": "material-icons",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pricing-plans__list__item__content__features-list__vega-option__text",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("pricing.aiOption")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "pricing-plans__features-list-toggler pricing-plans__features-list-toggler--popular pricing-plans__features-list-toggler--mobile",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "pricing-plans__features-list-toggler__button",
                    on: {
                      click: function ($event) {
                        return _vm.toggleFeaturesList("Pro")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.isOpenedProFeaturesList
                            ? _vm.$t("features.hideAllProFeatures")
                            : _vm.$t("features.seeAllProFeatures")
                        ) +
                        "\n\n        "
                    ),
                    _vm.isOpenedProFeaturesList
                      ? _c("img", {
                          staticClass:
                            "pricing-plans__features-list-toggler__button__icon",
                          attrs: {
                            src: require("@/assets/images/register-funnel/minus-circle.svg"),
                            alt: "Hide list",
                          },
                        })
                      : _c("img", {
                          staticClass:
                            "pricing-plans__features-list-toggler__button__icon",
                          attrs: {
                            src: require("@/assets/images/register-funnel/plus-circle.svg"),
                            alt: "Open list",
                          },
                        }),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "pricing-plans__list__item" }, [
              _c("div", { staticClass: "pricing-plans__list__item__heading" }, [
                _c(
                  "h5",
                  { staticClass: "pricing-plans__list__item__heading__title" },
                  [_vm._v(_vm._s(_vm.$t("pricing.enterprise")))]
                ),
              ]),
              _c("div", { staticClass: "pricing-plans__list__item__content" }, [
                _c(
                  "div",
                  { staticClass: "pricing-plans__list__item__content__price" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__price__amount pricing-plans__list__item__content__price__amount--custom-price",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("pricing.getACustomQuote")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "pricing-plans__heading pricing-plans__list__item__content__type",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "pricing-plans__heading__button",
                        class: {
                          "pricing-plans__heading__button--current":
                            _vm.subscriptionPlanType === "monthly",
                        },
                        on: {
                          click: function ($event) {
                            _vm.subscriptionPlanType = "monthly"
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__heading__button__title",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("capitalizeFirstLetter")(
                                  _vm.$t("pricing.monthly")
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__heading__button__label",
                          },
                          [_vm._v(_vm._s(_vm.$t("pricing.noSavings")))]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "pricing-plans__heading__button",
                        class: {
                          "pricing-plans__heading__button--current":
                            _vm.subscriptionPlanType === "annual",
                        },
                        on: {
                          click: function ($event) {
                            _vm.subscriptionPlanType = "annual"
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__heading__button__title",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("capitalizeFirstLetter")(
                                  _vm.$t("pricing.yearly")
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__heading__button__label",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("generateRightTranslations")(
                                  "10%",
                                  _vm.$i18n.locale
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "pricing-plans__list__item__content__users" },
                  [_vm._v(_vm._s(_vm.$t("pricing.forEstablishedTeams")))]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--desktop",
                    on: {
                      click: function () {
                        return _vm.selectPlan(
                          _vm.isAnnualPeriodType
                            ? _vm.M_ENTERPRISE_ANNUAL
                            : _vm.M_ENTERPRISE_MONTHLY
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("pricing.contactSales")) +
                        "\n        "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--mobile",
                    on: {
                      click: function () {
                        return _vm.selectPlan(
                          _vm.isAnnualPeriodType
                            ? _vm.M_ENTERPRISE_ANNUAL
                            : _vm.M_ENTERPRISE_MONTHLY
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("pricing.contactSales")) +
                        "\n        "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "pricing-plans__list__item__content__features-list",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list__item",
                      },
                      [
                        _c("ThinCheckIcon", {
                          staticClass:
                            "pricing-plans__list__item__content__features-list__item__icon",
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item__text pricing-plans__list__item__content__features-list__item__text--strong",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("features.proSolutionsBundle")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list__item",
                      },
                      [
                        _c("ThinCheckIcon", {
                          staticClass:
                            "pricing-plans__list__item__content__features-list__item__icon",
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item__text",
                          },
                          [_vm._v(_vm._s(_vm.$t("features.assignmentRules")))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list__item",
                      },
                      [
                        _c("ThinCheckIcon", {
                          staticClass:
                            "pricing-plans__list__item__content__features-list__item__icon",
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item__text",
                          },
                          [_vm._v(_vm._s(_vm.$t("features.advancedAnalytics")))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list__item",
                      },
                      [
                        _c("ThinCheckIcon", {
                          staticClass:
                            "pricing-plans__list__item__content__features-list__item__icon",
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item__text",
                          },
                          [_vm._v(_vm._s(_vm.$t("features.privacySupport")))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list__item",
                      },
                      [
                        _c("ThinCheckIcon", {
                          staticClass:
                            "pricing-plans__list__item__content__features-list__item__icon",
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item__text",
                          },
                          [_vm._v(_vm._s(_vm.$t("features.personalSetup")))]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "pricing-plans__list__item__content__features-list",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list--header",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("vue.optional")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "pricing-plans__list__item__content__features-list__vega-option",
                      },
                      [
                        _c(
                          "li",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__features-list__item__text",
                          },
                          [
                            _c("vs-icon", {
                              staticClass:
                                "pricing-plans__list__item__content__features-list__vega-option__icon",
                              attrs: {
                                size: "18px",
                                icon: "add",
                                "icon-pack": "material-icons",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__features-list__vega-option__text",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("pricing.aiOption")) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "pricing-plans__features-list-toggler pricing-plans__features-list-toggler--mobile",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "pricing-plans__features-list-toggler__button",
                    on: {
                      click: function ($event) {
                        return _vm.toggleFeaturesList("Enterprise")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.isOpenedEnterpriseFeaturesList
                            ? _vm.$t("features.hideAllEnterpriseFeatures")
                            : _vm.$t("features.seeAllEnterpriseFeatures")
                        ) +
                        "\n\n        "
                    ),
                    _vm.isOpenedEnterpriseFeaturesList
                      ? _c("img", {
                          staticClass:
                            "pricing-plans__features-list-toggler__button__icon",
                          attrs: {
                            src: require("@/assets/images/register-funnel/minus-circle.svg"),
                            alt: "Hide list",
                          },
                        })
                      : _c("img", {
                          staticClass:
                            "pricing-plans__features-list-toggler__button__icon",
                          attrs: {
                            src: require("@/assets/images/register-funnel/plus-circle.svg"),
                            alt: "Open list",
                          },
                        }),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "pricing-plans__features-list-toggler" }, [
        _c(
          "div",
          {
            staticClass: "pricing-plans__features-list-toggler__button",
            on: {
              click: function ($event) {
                return _vm.toggleFeaturesList("All")
              },
            },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.isOpenedFeaturesList
                    ? _vm.$t("features.hideAllFeatures")
                    : _vm.$t("features.seeAllFeatures")
                ) +
                "\n\n      "
            ),
            _vm.isOpenedFeaturesList
              ? _c("img", {
                  staticClass:
                    "pricing-plans__features-list-toggler__button__icon",
                  attrs: {
                    src: require("@/assets/images/register-funnel/minus-circle.svg"),
                    alt: "Hide list",
                  },
                })
              : _c("img", {
                  staticClass:
                    "pricing-plans__features-list-toggler__button__icon",
                  attrs: {
                    src: require("@/assets/images/register-funnel/plus-circle.svg"),
                    alt: "Open list",
                  },
                }),
          ]
        ),
      ]),
      _vm.isOpenedFeaturesList
        ? _c("div", { staticClass: "pricing-plans__features" }, [
            _c("div", { staticClass: "pricing-plans__features__heading" }, [
              _c(
                "div",
                { staticClass: "pricing-plans__features__heading__list" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "pricing-plans__features__heading__list__item",
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticClass:
                            "pricing-plans__features__heading__list__item__title",
                        },
                        [_vm._v(_vm._s(_vm.$t("pricing.basic")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pricing-plans__list__item__content__price",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pricing-plans__list__item__content__price__currency",
                            },
                            [_vm._v(_vm._s(_vm.currentCurrencySymbol))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pricing-plans__list__item__content__price__amount",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.subscriptionPlanType === "annual"
                                      ? _vm.basicPriceAnnual
                                      : _vm.basicPriceMonthly
                                  ) +
                                  "\n              "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "pricing-plans__list__item__content__price__amount__period",
                                },
                                [_vm._v("/mo")]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pricing-plans__list__item__content__button-main",
                          on: {
                            click: function () {
                              return _vm.selectPlan(
                                _vm.isAnnualPeriodType
                                  ? _vm.M_BASIC_ANNUAL
                                  : _vm.M_BASIC_MONTHLY
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("vue.choosePlan")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pricing-plans__features__heading__list__item",
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticClass:
                            "pricing-plans__features__heading__list__item__title",
                        },
                        [_vm._v(_vm._s(_vm.$t("pricing.pro")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pricing-plans__list__item__content__price",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pricing-plans__list__item__content__price__currency",
                            },
                            [_vm._v(_vm._s(_vm.currentCurrencySymbol))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pricing-plans__list__item__content__price__amount",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.subscriptionPlanType === "annual"
                                      ? _vm.proPriceAnnual
                                      : _vm.proPriceMonthly
                                  )
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "pricing-plans__list__item__content__price__amount__period",
                                },
                                [_vm._v("/mo")]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pricing-plans__list__item__content__button-main",
                          on: {
                            click: function () {
                              return _vm.selectPlan(
                                _vm.isAnnualPeriodType
                                  ? _vm.M_PROFESSIONAL_ANNUAL
                                  : _vm.M_PROFESSIONAL_MONTHLY
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.isLotteryWinner && _vm.isAnnualPeriodType
                                  ? _vm.$t("vue.getYourProfitNow")
                                  : _vm.$t("vue.choosePlan")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pricing-plans__features__heading__list__item",
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticClass:
                            "pricing-plans__features__heading__list__item__title",
                        },
                        [_vm._v(_vm._s(_vm.$t("pricing.enterprise")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pricing-plans__list__item__content__price",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pricing-plans__list__item__content__price__amount__period pricing-plans__list__item__content__price__amount__period--custom-price",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("pricing.customQuote")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pricing-plans__list__item__content__button-main",
                          on: {
                            click: function () {
                              return _vm.selectPlan(
                                _vm.isAnnualPeriodType
                                  ? _vm.M_ENTERPRISE_ANNUAL
                                  : _vm.M_ENTERPRISE_MONTHLY
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("pricing.contactSales")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "pricing-plans__features-props" },
              _vm._l(
                _vm.featuresList,
                function (featureSection, featureSectionIndex) {
                  return _c(
                    "div",
                    { key: featureSection.title },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pricing-plans__features-props__item-heading",
                          class: {
                            "pricing-plans__features-props__item-heading--rounded":
                              featureSectionIndex === 0,
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(featureSection.title) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._l(featureSection.items, function (feature) {
                        return _c(
                          "div",
                          {
                            key: feature.title,
                            staticClass: "pricing-plans__features-props__item",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__features-props__item__value",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pricing-plans__features-props__item__value__title",
                                  },
                                  [_vm._v(_vm._s(feature.title))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pricing-plans__features-props__item__value__description",
                                  },
                                  [_vm._v(_vm._s(feature.description))]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__features-props__item__marks-list",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pricing-plans__features-props__item__marks-list__item",
                                  },
                                  [
                                    feature.isCustomField
                                      ? [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(feature.customTextFree) +
                                              "\n              "
                                          ),
                                        ]
                                      : [
                                          feature.isFree
                                            ? _c("ThinCheckIcon", {
                                                staticClass:
                                                  "pricing-plans__features-props__item__marks-list__item__icon",
                                                attrs: { color: "#3034F7" },
                                              })
                                            : _c("img", {
                                                staticClass:
                                                  "pricing-plans__features-props__item__marks-list__item__icon",
                                                attrs: {
                                                  src: require("@/assets/images/register-funnel/cross.svg"),
                                                  alt: "Check",
                                                },
                                              }),
                                        ],
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pricing-plans__features-props__item__marks-list__item",
                                  },
                                  [
                                    feature.isCustomField
                                      ? [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(feature.customTextBasic) +
                                              "\n              "
                                          ),
                                        ]
                                      : [
                                          feature.isBasic
                                            ? _c("ThinCheckIcon", {
                                                staticClass:
                                                  "pricing-plans__features-props__item__marks-list__item__icon",
                                                attrs: { color: "#3034F7" },
                                              })
                                            : _c("img", {
                                                staticClass:
                                                  "pricing-plans__features-props__item__marks-list__item__icon",
                                                attrs: {
                                                  src: require("@/assets/images/register-funnel/cross.svg"),
                                                  alt: "Check",
                                                },
                                              }),
                                        ],
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pricing-plans__features-props__item__marks-list__item",
                                  },
                                  [
                                    feature.isCustomField
                                      ? [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(feature.customTextPro) +
                                              "\n              "
                                          ),
                                        ]
                                      : [
                                          feature.isPro
                                            ? _c("ThinCheckIcon", {
                                                staticClass:
                                                  "pricing-plans__features-props__item__marks-list__item__icon",
                                                attrs: { color: "#3034F7" },
                                              })
                                            : _c("img", {
                                                staticClass:
                                                  "pricing-plans__features-props__item__marks-list__item__icon",
                                                attrs: {
                                                  src: require("@/assets/images/register-funnel/cross.svg"),
                                                  alt: "Check",
                                                },
                                              }),
                                        ],
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pricing-plans__features-props__item__marks-list__item",
                                  },
                                  [
                                    feature.isCustomField
                                      ? [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                feature.customTextEnterprise
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      : [
                                          feature.isEnterprise
                                            ? _c("ThinCheckIcon", {
                                                staticClass:
                                                  "pricing-plans__features-props__item__marks-list__item__icon",
                                                attrs: { color: "#3034F7" },
                                              })
                                            : _c("img", {
                                                staticClass:
                                                  "pricing-plans__features-props__item__marks-list__item__icon",
                                                attrs: {
                                                  src: require("@/assets/images/register-funnel/cross.svg"),
                                                  alt: "Check",
                                                },
                                              }),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }
              ),
              0
            ),
          ])
        : _vm._e(),
      _vm.isOpenedFeaturesListOnMobile
        ? _c("div", { staticClass: "pricing-plans__features-mobile-list" }, [
            _c(
              "div",
              { staticClass: "pricing-plans__features-mobile-list__item" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "pricing-plans__features-mobile-list__item__heading",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__features-mobile-list__item__heading__main",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__features-mobile-list__item__heading__title",
                          },
                          [_vm._v("Basic")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__price",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__price__currency",
                              },
                              [_vm._v(_vm._s(_vm.currentCurrencySymbol))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__price__amount",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.subscriptionPlanType === "annual"
                                        ? _vm.basicPriceAnnual
                                        : _vm.basicPriceMonthly
                                    )
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "pricing-plans__list__item__content__price__amount__period",
                                  },
                                  [_vm._v("/mo")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm.isOpenedBasicFeaturesList
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--mobile",
                            on: {
                              click: function () {
                                return _vm.selectPlan(
                                  _vm.isAnnualPeriodType
                                    ? _vm.M_BASIC_ANNUAL
                                    : _vm.M_BASIC_MONTHLY
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("vue.choosePlan")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm.isOpenedBasicFeaturesList
                  ? _c(
                      "div",
                      { staticClass: "pricing-plans__features-props" },
                      _vm._l(_vm.featuresList, function (featureSection) {
                        return _c(
                          "div",
                          { key: featureSection.title },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__features-props__item-heading",
                              },
                              [_vm._v(_vm._s(featureSection.title))]
                            ),
                            _vm._l(featureSection.items, function (feature) {
                              return _c(
                                "div",
                                {
                                  key: feature.title,
                                  staticClass:
                                    "pricing-plans__features-props__item",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pricing-plans__features-props__item__value",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-plans__features-props__item__value__title",
                                        },
                                        [_vm._v(_vm._s(feature.title))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-plans__features-props__item__value__description",
                                        },
                                        [_vm._v(_vm._s(feature.description))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pricing-plans__features-props__item__marks-list",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-plans__features-props__item__marks-list__item",
                                        },
                                        [
                                          feature.isCustomField
                                            ? [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      feature.customTextBasic
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            : [
                                                feature.isBasic
                                                  ? _c("ThinCheckIcon", {
                                                      staticClass:
                                                        "pricing-plans__features-props__item__marks-list__item__icon",
                                                      attrs: {
                                                        color: "#3034F7",
                                                      },
                                                    })
                                                  : _c("img", {
                                                      staticClass:
                                                        "pricing-plans__features-props__item__marks-list__item__icon",
                                                      attrs: {
                                                        src: require("@/assets/images/register-funnel/cross.svg"),
                                                        alt: "Check",
                                                      },
                                                    }),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "pricing-plans__features-mobile-list__item pricing-plans__features-mobile-list__item--popular",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "pricing-plans__features-mobile-list__item__heading",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__features-mobile-list__item__heading__main",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__features-mobile-list__item__heading__title",
                          },
                          [_vm._v("Pro")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__price",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__price__currency",
                              },
                              [_vm._v(_vm._s(_vm.currentCurrencySymbol))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__price__amount",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.subscriptionPlanType === "annual"
                                        ? _vm.proPriceAnnual
                                        : _vm.proPriceMonthly
                                    )
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "pricing-plans__list__item__content__price__amount__period",
                                  },
                                  [_vm._v("/mo")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm.isOpenedProFeaturesList
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--mobile",
                            on: {
                              click: function () {
                                return _vm.selectPlan(
                                  _vm.isAnnualPeriodType
                                    ? _vm.M_PROFESSIONAL_ANNUAL
                                    : _vm.M_PROFESSIONAL_MONTHLY
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.isLotteryWinner && _vm.isAnnualPeriodType
                                    ? _vm.$t("vue.getYourProfitNow")
                                    : _vm.$t("vue.choosePlan")
                                ) +
                                "\n\n          "
                            ),
                            _c("img", {
                              staticClass:
                                "pricing-plans__list__item__content__button-main__icon",
                              attrs: {
                                src: require("@/assets/images/register-funnel/forward-arrow.png"),
                                alt: "Next",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm.isOpenedProFeaturesList
                  ? _c(
                      "div",
                      { staticClass: "pricing-plans__features-props" },
                      _vm._l(_vm.featuresList, function (featureSection) {
                        return _c(
                          "div",
                          { key: featureSection.title },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__features-props__item-heading",
                              },
                              [_vm._v(_vm._s(featureSection.title))]
                            ),
                            _vm._l(featureSection.items, function (feature) {
                              return _c(
                                "div",
                                {
                                  key: feature.title,
                                  staticClass:
                                    "pricing-plans__features-props__item",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pricing-plans__features-props__item__value",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-plans__features-props__item__value__title",
                                        },
                                        [_vm._v(_vm._s(feature.title))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-plans__features-props__item__value__description",
                                        },
                                        [_vm._v(_vm._s(feature.description))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pricing-plans__features-props__item__marks-list",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-plans__features-props__item__marks-list__item",
                                        },
                                        [
                                          feature.isCustomField
                                            ? [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      feature.customTextPro
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            : [
                                                feature.isPro
                                                  ? _c("ThinCheckIcon", {
                                                      staticClass:
                                                        "pricing-plans__features-props__item__marks-list__item__icon",
                                                      attrs: {
                                                        color: "#3034F7",
                                                      },
                                                    })
                                                  : _c("img", {
                                                      staticClass:
                                                        "pricing-plans__features-props__item__marks-list__item__icon",
                                                      attrs: {
                                                        src: require("@/assets/images/register-funnel/cross.svg"),
                                                        alt: "Check",
                                                      },
                                                    }),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              { staticClass: "pricing-plans__features-mobile-list__item" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "pricing-plans__features-mobile-list__item__heading",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pricing-plans__features-mobile-list__item__heading__main",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__features-mobile-list__item__heading__title",
                          },
                          [_vm._v("Enterprise")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__price",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__list__item__content__price__amount pricing-plans__list__item__content__price__amount--custom-price",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("pricing.customQuote")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm.isOpenedEnterpriseFeaturesList
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "pricing-plans__list__item__content__button-main pricing-plans__list__item__content__button-main--mobile",
                            on: {
                              click: function () {
                                return _vm.selectPlan(
                                  _vm.isAnnualPeriodType
                                    ? _vm.M_ENTERPRISE_ANNUAL
                                    : _vm.M_ENTERPRISE_MONTHLY
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("pricing.contactSales")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm.isOpenedEnterpriseFeaturesList
                  ? _c(
                      "div",
                      { staticClass: "pricing-plans__features-props" },
                      _vm._l(_vm.featuresList, function (featureSection) {
                        return _c(
                          "div",
                          { key: featureSection.title },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pricing-plans__features-props__item-heading",
                              },
                              [_vm._v(_vm._s(featureSection.title))]
                            ),
                            _vm._l(featureSection.items, function (feature) {
                              return _c(
                                "div",
                                {
                                  key: feature.title,
                                  staticClass:
                                    "pricing-plans__features-props__item",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pricing-plans__features-props__item__value",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-plans__features-props__item__value__title",
                                        },
                                        [_vm._v(_vm._s(feature.title))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-plans__features-props__item__value__description",
                                        },
                                        [_vm._v(_vm._s(feature.description))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pricing-plans__features-props__item__marks-list",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-plans__features-props__item__marks-list__item",
                                        },
                                        [
                                          feature.isCustomField
                                            ? [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      feature.customTextEnterprise
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            : [
                                                feature.isEnterprise
                                                  ? _c("ThinCheckIcon", {
                                                      staticClass:
                                                        "pricing-plans__features-props__item__marks-list__item__icon",
                                                      attrs: {
                                                        color: "#3034F7",
                                                      },
                                                    })
                                                  : _c("img", {
                                                      staticClass:
                                                        "pricing-plans__features-props__item__marks-list__item__icon",
                                                      attrs: {
                                                        src: require("@/assets/images/register-funnel/cross.svg"),
                                                        alt: "Check",
                                                      },
                                                    }),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "pricing-plans__features-mobile-list__button",
                on: { click: _vm.returnToAllPlans },
              },
              [
                _c("ArrowLongIcon", {
                  staticClass: "mr-2",
                  attrs: { color: "rgb(135, 136, 148)", direction: "left" },
                }),
                _vm._v("\n\n      Return to All Plans\n    "),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }